import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "72",
  height: "72",
  viewBox: "0 0 72 72",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  class: "fill-current",
  "aria-hidden": "true"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M28.0373 11.4881L27.4849 9.96643L3.01648 21.024L9.28059 34.9156L16.9444 31.6247V63.4165H55.0556V31.6247L62.7194 34.9156L68.9835 21.0239L45.4237 10.3783L44.8352 11.8185C43.4922 15.1051 40.2642 17.4165 36.5 17.4165C32.6105 17.4165 29.2936 14.9484 28.0373 11.4881ZM10.8042 30.9964L6.98349 22.5234L25.8697 13.9885C27.8761 17.8085 31.882 20.4165 36.5 20.4165C40.9703 20.4165 44.867 17.9724 46.9314 14.3516L65.0165 22.5234L61.1957 30.9964L52.0556 27.0716V60.4165H19.9444V27.0716L10.8042 30.9964ZM46.926 34.6065C47.307 34.2067 47.2918 33.5738 46.892 33.1927C46.4922 32.8117 45.8592 32.8269 45.4782 33.2267C43.9849 34.7936 42.6736 35.9326 41.5424 36.6736C40.3982 37.4231 39.5256 37.7079 38.8881 37.7079C38.4972 37.7079 38.049 37.5372 37.4919 37.143C36.9322 36.7469 36.3717 36.2065 35.7406 35.5944L35.7114 35.5661C35.1149 34.9875 34.4505 34.3432 33.7516 33.8488C33.0388 33.3447 32.1824 32.9166 31.1992 32.9166C29.9846 32.9166 28.799 33.4453 27.6631 34.3232C26.5254 35.2025 25.3692 36.4837 24.1884 38.1236C23.8657 38.5718 23.9675 39.1967 24.4156 39.5194C24.8638 39.8421 25.4888 39.7404 25.8115 39.2922C26.9305 37.738 27.9574 36.6235 28.8861 35.9057C29.8166 35.1865 30.5807 34.9166 31.1992 34.9166C31.5907 34.9166 32.0394 35.0875 32.5967 35.4817C33.1566 35.8777 33.7171 36.4181 34.3482 37.0301L34.3784 37.0595L34.3785 37.0595C34.9747 37.6377 35.6385 38.2816 36.3367 38.7756C37.0491 39.2797 37.9053 39.7079 38.8881 39.7079C40.0824 39.7079 41.3446 39.1941 42.6384 38.3466C43.9452 37.4905 45.3751 36.2338 46.926 34.6065Z"
    }, null, -1)
  ])))
}
export default { render: render }