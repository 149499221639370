import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "72",
  height: "72",
  viewBox: "0 0 72 72",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  class: "fill-current",
  "aria-hidden": "true"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M37 33.9022V11.0434C45.2769 11.5235 52.4945 15.9513 56.798 22.4719L37 33.9022ZM34 35.6343V11.0434C20.6149 11.8198 10 22.9203 10 36.5C10 40.6103 10.9725 44.4934 12.6999 47.9319L34 35.6343ZM14.2023 50.5286C18.7639 57.4399 26.5994 62 35.5 62C49.5833 62 61 50.5833 61 36.5C61 32.3899 60.0276 28.5069 58.3004 25.0686L14.2023 50.5286ZM7 36.5C7 20.7599 19.7599 8 35.5 8C51.2401 8 64 20.7599 64 36.5C64 52.2401 51.2401 65 35.5 65C19.7599 65 7 52.2401 7 36.5Z"
    }, null, -1)
  ])))
}
export default { render: render }