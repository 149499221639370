import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "72",
  height: "72",
  viewBox: "0 0 72 72",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  class: "fill-current",
  "aria-hidden": "true"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M6.5 35.195C6.5 19.3472 19.3472 6.5 35.195 6.5C51.0428 6.5 63.89 19.3472 63.89 35.195C63.89 51.0206 51.3698 63.89 35.5 63.89C35.4625 63.89 35.4252 63.8886 35.3884 63.8859C35.3547 63.8882 35.3208 63.8893 35.2865 63.8893C34.4581 63.8893 33.7865 63.2177 33.7865 62.3893V48.72H31.3102C28.234 48.72 25.7402 46.2262 25.7402 43.15V27.96H29.8101V21.801C29.8101 20.9726 30.4816 20.301 31.3101 20.301C32.1385 20.301 32.8101 20.9726 32.8101 21.801V27.96H37.9497V21.801C37.9497 20.9726 38.6213 20.301 39.4497 20.301C40.2781 20.301 40.9497 20.9726 40.9497 21.801V27.96H44.6502V43.15C44.6502 46.2262 42.1565 48.72 39.0802 48.72H36.7865V60.8583C50.3423 60.1879 60.89 48.9751 60.89 35.195C60.89 21.004 49.386 9.5 35.195 9.5C21.004 9.5 9.5 21.004 9.5 35.195C9.5 36.0234 8.82843 36.695 8 36.695C7.17157 36.695 6.5 36.0234 6.5 35.195ZM28.7402 30.96V43.15C28.7402 44.5693 29.8909 45.72 31.3102 45.72H39.0802C40.4996 45.72 41.6502 44.5693 41.6502 43.15V30.96H28.7402Z"
    }, null, -1)
  ])))
}
export default { render: render }