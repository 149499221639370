import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "72",
  height: "72",
  viewBox: "0 0 72 72",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  class: "fill-current",
  "aria-hidden": "true"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M19.4622 11C18.7368 11 18.027 11.2104 17.4188 11.6057C16.8106 12.0009 16.33 12.5641 16.0354 13.227L8.02519 31.25H4.5C3.67157 31.25 3 31.9216 3 32.75C3 33.5784 3.67157 34.25 4.5 34.25H7.5V50.75V57.5C7.5 58.4946 7.89509 59.4484 8.59835 60.1517C9.30161 60.8549 10.2554 61.25 11.25 61.25H18C18.9946 61.25 19.9484 60.8549 20.6516 60.1517C21.3549 59.4484 21.75 58.4946 21.75 57.5V52.25H50.25V57.5C50.25 58.4946 50.6451 59.4484 51.3483 60.1517C52.0516 60.8549 53.0054 61.25 54 61.25H60.75C61.7446 61.25 62.6984 60.8549 63.4017 60.1517C64.1049 59.4484 64.5 58.4946 64.5 57.5V50.75V34.25H67.5C68.3284 34.25 69 33.5784 69 32.75C69 31.9216 68.3284 31.25 67.5 31.25H63.9748L55.9646 13.227C55.67 12.5641 55.1894 12.0009 54.5812 11.6057C53.973 11.2104 53.2632 11 52.5378 11H19.4622ZM60.6919 31.25L53.2231 14.4454C53.1642 14.3128 53.0681 14.2002 52.9465 14.1211C52.8248 14.0421 52.6829 14 52.5378 14H19.4622C19.3171 14 19.1752 14.0421 19.0535 14.1211C18.9319 14.2002 18.8358 14.3128 18.7769 14.4454L11.3081 31.25H60.6919ZM10.5 34.25V49.25H20.25H51.75H61.5V34.25H10.5ZM53.25 52.25V57.5C53.25 57.6989 53.329 57.8897 53.4697 58.0303C53.6103 58.171 53.8011 58.25 54 58.25H60.75C60.9489 58.25 61.1397 58.171 61.2803 58.0303C61.421 57.8897 61.5 57.6989 61.5 57.5V52.25H53.25ZM18.75 52.25H10.5V57.5C10.5 57.6989 10.579 57.8897 10.7197 58.0303C10.8603 58.171 11.0511 58.25 11.25 58.25H18C18.1989 58.25 18.3897 58.171 18.5303 58.0303C18.671 57.8897 18.75 57.6989 18.75 57.5V52.25ZM18 39.25C16.6193 39.25 15.5 40.3693 15.5 41.75C15.5 43.1307 16.6193 44.25 18 44.25H22.5C23.8807 44.25 25 43.1307 25 41.75C25 40.3693 23.8807 39.25 22.5 39.25H18ZM47 41.75C47 40.3693 48.1193 39.25 49.5 39.25H54C55.3807 39.25 56.5 40.3693 56.5 41.75C56.5 43.1307 55.3807 44.25 54 44.25H49.5C48.1193 44.25 47 43.1307 47 41.75Z"
    }, null, -1)
  ])))
}
export default { render: render }