import themeConfig from '@root/tailwind.config'
import { Ref, computed, unref, reactive, markRaw } from 'vue'
import { useI18n } from 'vue-i18n'
import { ActionCategory } from '@/api/useActions'
import IconEmployees from '@/assets/icons/Employees.svg?component'
import IconEnergy from '@/assets/icons/Energy.svg?component'
import IconManagement from '@/assets/icons/Management.svg?component'
import IconOfficeBuilding from '@/assets/icons/OfficeBuilding.svg?component'
import IconProduction from '@/assets/icons/Production.svg?component'
import IconTransportation from '@/assets/icons/Transportation.svg?component'

/**
 * Map {@link ActionCategory} to category properties
 * @returns config map with keyof {@link ActionCategory}
 */
export const useCategoryConfig = (
  currentCategory?: ActionCategory | Ref<ActionCategory>
) => {
  const { t } = useI18n()
  const colors = themeConfig.theme.colors
  const categoryConfig = reactive(
    new Map([
      [
        ActionCategory.Employees,
        {
          title: t('global.action.categories.employees'),
          description: t('global.action.categoriesDescription.employees'),
          icon: markRaw(IconEmployees),
          category: ActionCategory.Employees,
          theme: {
            background: 'gc-global-gradient-employees',
            fillSvg: colors.purple[500],
            color: 'text-purple-600',
          },
        },
      ],
      [
        ActionCategory.Management,
        {
          title: t('global.action.categories.management'),
          description: t('global.action.categoriesDescription.management'),
          icon: markRaw(IconManagement),
          category: ActionCategory.Management,
          theme: {
            background: 'gc-global-gradient-management',
            fillSvg: colors.lime[300],
            color: 'text-lime-300',
          },
        },
      ],
      [
        ActionCategory.CompanyVehicles,
        {
          title: t('global.action.categories.vehicles'),
          description: t('global.action.categoriesDescription.vehicles'),
          icon: markRaw(IconTransportation),
          category: ActionCategory.CompanyVehicles,
          theme: {
            background: 'gc-global-gradient-vehicles',
            fillSvg: colors.red[500],
            color: 'text-red-500',
          },
        },
      ],
      [
        ActionCategory.OfficeBuilding,
        {
          title: t('global.action.categories.officeBuilding'),
          description: t('global.action.categoriesDescription.officeBuilding'),
          icon: markRaw(IconOfficeBuilding),
          category: ActionCategory.OfficeBuilding,
          theme: {
            background: 'gc-global-gradient-office',
            fillSvg: colors.blue[400],
            color: 'text-blue-400',
          },
        },
      ],
      [
        ActionCategory.OwnElectricity,
        {
          title: t('global.action.categories.electricity'),
          description: t('global.action.categoriesDescription.electricity'),
          icon: markRaw(IconEnergy),
          category: ActionCategory.OwnElectricity,
          theme: {
            background: 'gc-global-gradient-electricity',
            fillSvg: colors.pink[400],
            color: 'text-pink-400',
          },
        },
      ],
      [
        ActionCategory.Product,
        {
          title: t('global.action.categories.product'),
          description: t('global.action.categoriesDescription.product'),
          icon: markRaw(IconProduction),
          category: ActionCategory.Product,
          theme: {
            background: 'gc-global-gradient-product',
            fillSvg: colors.cyan[600],
            color: 'text-cyan',
          },
        },
      ],
    ])
  )

  if (!currentCategory) {
    return { categoryConfig }
  }
  const theme = computed(
    () => categoryConfig.get(unref(currentCategory))?.theme
  )
  const title = computed(
    () => categoryConfig.get(unref(currentCategory))?.title
  )
  const category = computed(
    () => categoryConfig.get(unref(currentCategory))?.category
  )
  const icon = computed(() => categoryConfig.get(unref(currentCategory))?.icon)

  return {
    theme,
    title,
    category,
    icon,
    categoryConfig,
  }
}
