import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "72",
  height: "72",
  viewBox: "0 0 72 72",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  class: "fill-current",
  "aria-hidden": "true"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M10.5 15.75C10.5 15.3358 10.8358 15 11.25 15H60.75C61.1642 15 61.5 15.3358 61.5 15.75V56.25C61.5 56.6642 61.1642 57 60.75 57H11.25C10.8358 57 10.5 56.6642 10.5 56.25V15.75ZM11.25 12C9.17893 12 7.5 13.6789 7.5 15.75V56.25C7.5 58.3211 9.17893 60 11.25 60H60.75C62.8211 60 64.5 58.3211 64.5 56.25V15.75C64.5 13.6789 62.8211 12 60.75 12H11.25ZM20.6533 33.75C20.6533 30.8505 23.0038 28.5 25.9033 28.5C28.8028 28.5 31.1533 30.8505 31.1533 33.75C31.1533 36.6495 28.8028 39 25.9033 39H25.9029C23.0036 38.9998 20.6533 36.6494 20.6533 33.75ZM30.9386 40.2857C32.8939 38.7771 34.1533 36.4106 34.1533 33.75C34.1533 29.1937 30.4597 25.5 25.9033 25.5C21.347 25.5 17.6533 29.1937 17.6533 33.75C17.6533 36.4106 18.9128 38.7771 20.868 40.2857C20.3818 40.5513 19.915 40.8563 19.4726 41.1989C17.632 42.6242 16.3172 44.6207 15.7346 46.8745C15.5273 47.6766 16.0094 48.4948 16.8115 48.7021C17.6136 48.9095 18.4318 48.4273 18.6392 47.6253C19.0553 46.0152 19.9946 44.589 21.3094 43.5708C22.6242 42.5526 24.2401 42.0001 25.9031 42H25.9033C27.5662 42 29.182 42.5524 30.4968 43.5705C31.8117 44.5886 32.7511 46.0147 33.1673 47.6248C33.3747 48.4268 34.193 48.9089 34.9951 48.7015C35.7971 48.4941 36.2792 47.6758 36.0718 46.8738C35.4891 44.62 34.1741 42.6237 32.3335 41.1984C31.8912 40.856 31.4246 40.5512 30.9386 40.2857ZM42.75 30C41.9216 30 41.25 30.6716 41.25 31.5C41.25 32.3284 41.9216 33 42.75 33H54C54.8284 33 55.5 32.3284 55.5 31.5C55.5 30.6716 54.8284 30 54 30H42.75ZM42.75 39C41.9216 39 41.25 39.6716 41.25 40.5C41.25 41.3284 41.9216 42 42.75 42H54C54.8284 42 55.5 41.3284 55.5 40.5C55.5 39.6716 54.8284 39 54 39H42.75Z"
    }, null, -1)
  ])))
}
export default { render: render }